import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { ToastContainer } from 'react-toastify';

import { I18nextProvider } from '@/i18n';

interface RootElementProps {
  children: JSX.Element;
}

export function RootElement(props: RootElementProps) {
  const { children } = props;
  return (
    <>
      <ToastContainer />
      <I18nextProvider>{children}</I18nextProvider>
    </>
  );
}
