export * from './common/Button';
export * from './common/Layout';
export * from './common/Layout/Footer';
export * from './common/LazyLoad';
export * from './common/Loading';
export * from './common/RootElement';
export * from './common/Seo';
//home page
export * from './pages/home/SectionFeatures';
export * from './pages/home/SectionFirstScreen';
export * from './pages/home/SectionInscription';
export * from './pages/home/SectionMintDice';
export * from './pages/home/SectionMoreGame';
export * from './pages/home/SectionSecondScreen';
