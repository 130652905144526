import cn from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import diceIcon from '@/assets/images/diceIcon.svg';
import MenuIcon from '@/assets/images/menuIcon.svg';
import { useDeviceSize, usePageScrolled } from '@/utils/hooks';

interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { isSmallDevice } = useDeviceSize(860);

  const isScrolled = usePageScrolled();

  useEffect(() => {
    if (!isSmallDevice) {
      setIsOpen(false);
    }
  }, [isSmallDevice]);

  return (
    <header
      aria-label="Inscription Game Homepage Header"
      className={cn('fixed inset-x-0 top-7 z-50 h-[88px] px-4', className)}
    >
      <div
        className={cn(
          'page-container flex min-h-[60px] flex-col justify-center rounded-[14px] border-[1px] border-[#293341]  py-4 font-BakbakOne font-[400] md:py-0',
          isScrolled
            ? 'bg-[#1a132480] bg-opacity-0 backdrop-blur-[20px]'
            : 'bg-[#252E3C]'
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex cursor-pointer select-none items-center">
            <Link to="/">
              <img className="h-[22px] md:h-[33px]" src={diceIcon} alt="icon" />
            </Link>
          </div>
          <div className="hidden gap-6 text-[16px] md:flex">
            <div
              className="cursor-pointer px-3"
              onClick={() => scrollTo('MintDice')}
            >
              $DICE
            </div>
            <div
              className="cursor-pointer px-3"
              onClick={() => scrollTo('Features')}
            >
              FEATURES
            </div>
            <div
              className="cursor-pointer px-3"
              onClick={() => scrollTo('MoreGame')}
            >
              GAMES
            </div>
            <div
              className="cursor-pointer px-3"
              onClick={() => scrollTo('Inscription2048')}
            >
              2048.INK
            </div>
          </div>
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={cn('flex  cursor-pointer self-center md:hidden')}
          >
            <img className="h-[22px]" src={MenuIcon} />
          </div>
        </div>

        {isOpen && (
          <div className="mt-2">
            <div
              className="cursor-pointer py-3"
              onClick={() => scrollTo('FirstScreen')}
            >
              HOME
            </div>
            <div
              className="cursor-pointer py-3"
              onClick={() => scrollTo('MintDice')}
            >
              $DICE
            </div>
            <div
              className="cursor-pointer py-3"
              onClick={() => scrollTo('Features')}
            >
              FEATURES
            </div>
            <div
              className="cursor-pointer py-3"
              onClick={() => scrollTo('MoreGame')}
            >
              GAMES
            </div>
            <div
              className="cursor-pointer py-3"
              onClick={() => scrollTo('Inscription2048')}
            >
              2048.INK
            </div>
          </div>
        )}
      </div>
    </header>
  );

  function scrollTo(id: string) {
    const el = document.getElementById(id);

    if (el) {
      setIsOpen(false);

      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } else {
      void navigate(`/#${id}`);
    }
  }
}
