import cn from 'classnames';
import React from 'react';

import Gradient from '@/assets/images/home/second_screen_gradient.inline.svg';
import secondScreenIcon from '@/assets/images/secondIcon.png';

export function SectionSecondScreen() {
  return (
    <div
      className={cn(
        'page-container relative flex min-h-[80vh] flex-col items-center justify-center'
      )}
    >
      <img
        className="h-[120px] md:h-[158px]"
        src={secondScreenIcon}
        alt="icon"
      />

      <div className="mt-[40px] text-center  text-4xl  uppercase md:text-5xl">
        The first inscription game collection on the EVM blockchains
      </div>

      <div className="mt-[40px] text-center text-[18px] text-[#B1BECB] md:text-[28px]">
        DICE revolutionizes blockchain gaming by embedding a rich array of
        classic and original meme games into inscriptions.
      </div>
      <Gradient className="absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/2" />
    </div>
  );
}
