import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Footer } from './Footer';
import { Header } from './Header';

interface LayoutProps {
  [key: string]: unknown;
  className?: string;
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { className, children } = props;

  return (
    <div className={cn(className)}>
      <Header />
      <main className="relative">{children}</main>
      <Footer />
    </div>
  );
}
