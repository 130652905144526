import type { PageProps } from 'gatsby';
import React from 'react';

import {
  SectionFeatures,
  SectionFirstScreen,
  SectionInscription,
  SectionMintDice,
  SectionMoreGame,
  SectionSecondScreen,
  Seo
} from '@/components';

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <div id="FirstScreen">
        <SectionFirstScreen />
      </div>

      <SectionSecondScreen />

      <div id="MintDice">
        <SectionMintDice />
      </div>
      <div id="Features">
        <SectionFeatures />
      </div>
      <div id="MoreGame">
        <SectionMoreGame />
      </div>
      <div id="Inscription2048">
        <SectionInscription />
      </div>
    </>
  );
};
export const Head = () => <Seo title="" />;
export default IndexPage;
