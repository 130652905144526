import cn from 'classnames';
import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

import case1 from '@/assets/images/demoInscription/case1.png';
import case2 from '@/assets/images/demoInscription/case2.png';
import case3 from '@/assets/images/demoInscription/case3.png';
import case4 from '@/assets/images/demoInscription/case4.png';
import { Button } from '@/components/common/Button';
import { link2048evm } from '@/utils/const';

export function SectionInscription() {
  const caseList = [case1, case2, case3, case4];

  return (
    <div
      className={cn(
        'page-container relative z-0 mb-20 pt-[200px] sm:mb-40 md:pt-[122px]'
      )}
    >
      <Fade>
        <Slide direction="up">
          <div
            className={cn(
              'animate__animated animate__slideInDown text-center text-5xl leading-[24px] md:text-7xl md:leading-[100px]'
            )}
          >
            2048.INK
          </div>
        </Slide>
      </Fade>
      <div className="mt-6 text-center text-[26px] uppercase leading-[30px] md:mt-4 md:text-[40px] md:leading-[60px]">
        Reimagining the Classic in Blockchain
      </div>

      <div className="mt-8 flex flex-col md:flex-row md:gap-2">
        <div className="w-full rounded-[30px] border-[1px] border-[#282635] bg-gradient-to-b from-[#1B2437] to-[#1A233500] px-8 py-7 md:w-[50%]">
          <div className="text-[20px] md:text-[32px]">
            Unique Inscriptions of a Classic
          </div>
          <div
            className={cn(
              'mt-3 font-BaiJamjuree  text-sm text-[#B1BECB] md:text-base'
            )}
          >
            Each 2048 token is a distinctive game copy, infused with varied and
            unique traits.
          </div>
        </div>
        <div
          className={cn(
            'mt-8 w-full rounded-[30px] border-[1px] border-[#282635] bg-gradient-to-b from-[#1B2437] to-[#1A233500] px-8 py-7 md:mt-0 md:w-[50%]'
          )}
        >
          <div className="text-[20px] md:text-[32px]">
            Exclusive Special Editions{' '}
          </div>
          <div
            className={cn(
              'mt-3 font-BaiJamjuree  text-sm text-[#B1BECB] md:text-base'
            )}
          >
            Discover rare finds like the golden game board and classic designs,
            elevating your gaming experience.
          </div>
        </div>
      </div>

      <div className="mt-[50px] flex justify-between">
        {caseList.map((item, index) => (
          <img className="w-[25%]" key={index} src={item} />
        ))}
      </div>

      <div className="mt-[60px] flex items-center justify-center">
        <a href={link2048evm} target="_blank">
          <button
            type="button"
            className={cn(
              'rounded-[10px] bg-gradient-to-r from-[#FF30D5] to-[#2E32FF] px-12 py-3 text-xl leading-[28px] text-[#FFFFFF] duration-200 hover:opacity-75 sm:mb-[10vh] sm:mt-[80px] md:px-[80px] md:text-3xl md:leading-[36px] lg:mt-[50px]'
            )}
          >
            <span>Mint & Play Now</span>
          </button>
        </a>
      </div>
    </div>
  );
}
