import cn from 'classnames';
import React from 'react';

import diceIcon from '@/assets/images/diceIcon.svg';
import emailIcon from '@/assets/images/email.svg';
import footerBg from '@/assets/images/footerBg.png';
import telegramIcon from '@/assets/images/telegram.svg';

export function Footer() {
  return (
    <div
      className="w-full"
      style={{
        background: `url(${footerBg}) no-repeat`,
        backgroundSize: '100% 100%'
      }}
    >
      <footer
        className={cn(
          'page-container flex h-[168px] items-center justify-between text-[#97A2B8]'
        )}
      >
        <div className="flex items-center">
          <img className="h-[24px] md:h-[40px]" src={diceIcon} alt="icon" />
        </div>

        <div className="inline-flex items-center gap-2">
          <a href="mailto:contact@playdice.ink">
            <img src={emailIcon} />
          </a>
          <a href="https://t.me/DICEInscription" target="_blank">
            <img src={telegramIcon} />
          </a>
        </div>
      </footer>
    </div>
  );
}
