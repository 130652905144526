import './style.css';

import React from 'react';

import cubeBackGif from '@/assets/images/cube/back.gif';
import cubeBottomGif from '@/assets/images/cube/bottom.gif';
import cubeFrontGif from '@/assets/images/cube/front.webp';
import cubeLeftGif from '@/assets/images/cube/left.gif';
import cubeRightGif from '@/assets/images/cube/right.gif';
import cubeTopGif from '@/assets/images/cube/top.gif';

export default function Index() {
  return (
    <div className="space3D">
      {/* <input type="checkbox" hidden id="easter" />
      <label className="side top" htmlFor="easter"></label>
      <label className="side bottom" htmlFor="easter"></label>
      <label className="side left" htmlFor="easter"></label>
      <label className="side right" htmlFor="easter"></label> */}
      <div className="box">
        <div
          className="face front"
          style={{
            backgroundImage: `url(${cubeFrontGif})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className="face top"
          style={{
            backgroundImage: `url(${cubeTopGif})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className="face bottom"
          style={{
            backgroundImage: `url(${cubeBottomGif})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className="face left"
          style={{
            backgroundImage: `url(${cubeLeftGif})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className="face right"
          style={{
            backgroundImage: `url(${cubeRightGif})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div
          className="face back"
          style={{
            backgroundImage: `url(${cubeBackGif})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
