import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Cube from '@/components/css-cube';

export function SectionMoreGame() {
  const divRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(1);
  const [opacity, setOpacity] = useState<number>(1);
  const [activeGift, setActiveGift] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeGift === 0) {
        setActiveGift(1);
      } else if (activeGift === 1) {
        setActiveGift(2);
      } else if (activeGift === 2) {
        setActiveGift(3);
      } else if (activeGift === 3) {
        setActiveGift(0);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [activeGift]);

  useEffect(() => {
    const checkIfCentered = () => {
      const div = divRef.current;
      if (div) {
        const rect = div.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const distanceAbove = rect.top + rect.height / 2 - windowHeight / 2;

        if (distanceAbove > 0) {
          setScale(2);
          setOpacity(0);
        } else if (distanceAbove > -200 && distanceAbove < 0) {
          const newScale =
            2 + distanceAbove * 0.005 < 1 ? 1 : 2 + distanceAbove * 0.005;
          const newOpacity =
            -(distanceAbove * 0.0025) > 1 ? 1 : -distanceAbove * 0.0025;
          setScale(newScale);
          setOpacity(newOpacity);
        } else {
          setScale(1);
          setOpacity(1);
        }
      }
    };

    checkIfCentered();

    window.addEventListener('scroll', checkIfCentered);

    return () => {
      window.removeEventListener('scroll', checkIfCentered);
    };
  }, [setOpacity]);

  return (
    <div
      className={cn(
        'page-container flex flex-col justify-center pt-[88px] md:min-h-[100vh] md:pt-[122px]'
      )}
    >
      <div
        ref={divRef}
        className={cn('hidden text-center text-7xl leading-[70px] md:block')}
        style={{ transform: `scale(${scale})` }}
      >
        MORE GAMES AND MEMES
      </div>

      <div
        className={cn('block text-center text-5xl leading-[61px] md:hidden')}
      >
        MORE GAMES AND MEMES
      </div>

      <div
        className="relative flex items-center justify-center"
        style={{ opacity: `${opacity}` }}
      >
        <div className="h-[50vh] w-full">
          <Cube />
        </div>
      </div>
    </div>
  );
}
