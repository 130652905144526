import cn from 'classnames';
import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

import bnbIcon from '@/assets/images/bnb.svg';
import bg from '@/assets/images/mintContainerBg.png';
import mintInscription from '@/assets/lottie/mint_inscription.json';

import { LottieInView } from '../../common/LottieInView';

export function SectionMintDice() {
  return (
    <div className="page-container relative z-0 flex h-[100vh] flex-col items-center justify-center pb-[80px] pt-0 md:pb-[200px] md:pt-[200px]">
      <img
        className="absolute left-[50%] top-0 z-[-1] w-full translate-x-[-50%]"
        src={bg}
      />
      <Fade>
        <Slide direction="up">
          <div
            className={cn(
              'animate__animated animate__slideInUp text-center text-5xl leading-[48px] md:text-6xl md:leading-[61px]'
            )}
          >
            MINT{' '}
            <span
              style={{
                backgroundImage:
                  'linear-gradient(to right, #FF2CC4E5, #516CFFE5)',
                WebkitBackgroundClip: 'text',
                color: 'transparent'
              }}
            >
              $DICE
            </span>{' '}
            HERE
          </div>
        </Slide>
      </Fade>

      <div className="flex w-[100%] justify-center md:mt-10 md:py-10">
        <LottieInView
          className="w-full"
          isLoop={true}
          animationData={mintInscription}
        />
      </div>

      <div className="flex flex-col justify-center gap-[10px] text-center text-[16px] leading-[24px] md:mt-10 md:flex-row md:gap-4 md:text-[18px] md:leading-[36px]">
        <div className="inline-flex w-full items-center gap-2 rounded-lg border border-[#3A445A] bg-[#1A1E27] px-4 py-1 md:w-auto">
          <img src={bnbIcon} />
          <span className="font-bold text-[#E5B31C]"> BNB Smart Chain</span>
        </div>
        <div className="inline-flex w-full rounded-lg border border-[#3A445A] bg-[#1A1E27] md:w-auto">
          <div className="rounded-l-lg border-r border-[#3A445A] bg-[#232834] px-4 py-1 text-[#B1BECB]">
            Protocol
          </div>
          <div className="px-6 py-1 text-[#E5B31C]">BEP-20</div>
        </div>

        <div className="inline-flex w-full rounded-lg border border-[#3A445A] bg-[#1A1E27] md:w-auto">
          <div className="rounded-l-lg border-r border-[#3A445A] bg-[#232834] px-4 py-1 text-[#B1BECB]">
            Total Supply
          </div>
          <div className="inline-block bg-gradient-to-r from-[#FF40D8] to-[#565AFF] bg-clip-text  px-6 py-1 text-transparent">
            21,000,000
          </div>
        </div>
      </div>

      <div className="mt-[30px] flex items-center justify-center md:mt-[60px]">
        <button
          type="button"
          className={cn(
            'mt-15 rounded-[10px] bg-gradient-to-r from-[#FF30D5] to-[#2E32FF] px-8 py-2 text-[16px] leading-[28px] text-[#FFFFFF] hover:opacity-75 md:px-[50px] md:text-[24px] md:leading-[36px]'
          )}
          onClick={() =>
            window.open(
              'https://evm.ink/tokens?searchTick=DICE&chainId=eip155%3A56&protocol=bep-20'
            )
          }
        >
          <span>View on EVM.ink</span>
        </button>
      </div>
    </div>
  );
}
